// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-tsx": () => import("/Users/codahk/Sites/katiesitegatsby/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("/Users/codahk/Sites/katiesitegatsby/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-gallery-bugs-tsx": () => import("/Users/codahk/Sites/katiesitegatsby/src/pages/gallery/bugs.tsx" /* webpackChunkName: "component---src-pages-gallery-bugs-tsx" */),
  "component---src-pages-gallery-index-tsx": () => import("/Users/codahk/Sites/katiesitegatsby/src/pages/gallery/index.tsx" /* webpackChunkName: "component---src-pages-gallery-index-tsx" */),
  "component---src-pages-gallery-pets-tsx": () => import("/Users/codahk/Sites/katiesitegatsby/src/pages/gallery/pets.tsx" /* webpackChunkName: "component---src-pages-gallery-pets-tsx" */),
  "component---src-pages-gallery-science-tsx": () => import("/Users/codahk/Sites/katiesitegatsby/src/pages/gallery/science.tsx" /* webpackChunkName: "component---src-pages-gallery-science-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/codahk/Sites/katiesitegatsby/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

